import React, { useEffect, useRef, useState } from "react";
import AgentsRequests from "../apiRequests/AgentsRequests";
import axios from "axios";
import * as constants from "../constants";
import logo from "../assets/images/clientlogo.png";

const agentsRequests = new AgentsRequests();

const AgentListing = () => {

    const [loading, setLoading] = useState(false);
    const [agentLoading, setAgentLoading] = useState(false);
    const [userCodes, setUserCodes] = useState([]);

    const [orgData, setOrgData] = useState(null);
    const [campusData, setCampusData] = useState([]);
    
    const [agentListing, setAgentListing] = useState(null);
    
    const [searchText, setSearchText] = useState("");
    const [countryId, setCountryId] = useState(0);
    const [searchType, setSearchType] = useState(0);
    const [pageNo, setPagenNo] = useState(1);
    const [pageLength, setPageLength] = useState(50);
    
    const timeoutRef = useRef(null);



    const getUserCodes = async () => {
        try {
            const res = await axios.get(constants.BASEURL + "UserCode/getUserCode?codeType=3");
            setUserCodes(res.data);
        } catch (error) {
            console.log(error)
        }
    }

    const getData = async () => {
        try {
            setLoading(true);
            const detailsResponse = await agentsRequests.getAgentPublicDetails();
            if(detailsResponse !== "error"){
                setOrgData(detailsResponse);
                setCampusData(detailsResponse?.campuses);
            }
        }
        catch(error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    };

    const getAgentdata = async () => {
        try {
            setAgentLoading(true);
            const dataReponse = await agentsRequests.getAgentPublicListing(searchType !== 0 ? searchType : 2, searchText, countryId, searchText !== "" ? 1 : pageNo, pageLength);
            if(dataReponse !== "error"){
                setPagenNo(dataReponse?.pageNumber);
                setPageLength(dataReponse?.pageSize);
                setAgentListing(dataReponse);
            }
        }
        catch(error) {
            console.log(error)
        } finally {
            setAgentLoading(false);
        }
    };

    useEffect(() => {
        getUserCodes();
        getData();
    }, []);

    const handleSearchChange = (val) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            setSearchText(val);
        }, 800); 
    }

    useEffect(() => {
        
        getAgentdata();

    }, [searchText, countryId, pageNo, pageLength]);

    if(loading){
        return (
            <div className="grid place-content-center h-[100vh]">
                <div className="new-loader-container -z-10">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <p className="text-xs">Loading...</p>
            </div>
        )
    }

  return (
    
    <main className="py-4 px-10">
        <div>            
            {orgData && orgData?.showLogo ? (
                <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-4 mb-10">
                    {campusData?.map((campus) => (
                        <div key={campus.campusId} className="border rounded-lg bg-gray-200">
                            <div className="flex">
                                {campus.campus_Logo && (
                                    <img 
                                        src={`data:image/jpeg;base64,${campus.campus_Logo}`} 
                                        alt="Campus Logo"
                                        className="object-contain pl-2"
                                        width={400}
                                        height={300}
                                    />
                                )}

                                <div className="space-y-1 px-4 py-1.5 w-full">
                                    <p className="text-2xl font-medium mb-4">{campus?.campusName}</p>
                                    {campus?.addressLine1 !== "" && <p className="text-sm">{campus?.addressLine1}</p>}
                                    {campus?.addressLine2 !== "" && <p className="text-sm">{campus?.addressLine2}</p>}
                                    {campus?.addressLine3 !== "" && <p className="text-sm">{campus?.addressLine3}</p>}
                                    <p className="text-sm">{campus?.lSuburb} {campus?.state} {campus?.postCode} {campus?.country}</p>
                                    {campus?.phone !== "" && <p className="text-sm"><span className="font-medium">Phone</span> - {campus?.phone}</p>}
                                    {campus?.fax !== "" && <p className="text-sm"><span className="font-medium">Fax</span> - {campus?.fax}</p>}
                                    {campus?.email !== "" && <p className="text-sm"><span className="font-medium">Email</span> - {campus?.email}</p>}
                                    {campus?.webSite !== "" && <p className="text-sm"><span className="font-medium">Website</span> - {campus?.webSite}</p>}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="border rounded-lg mb-10 overflow-hidden bg-gray-200">
                    <div className="flex">
                        {(orgData?.companyLogo && orgData?.companyLogo.trim() != "") && (
                            <img
                                className="object-contain"
                                // src={orgData?.companyLogo} 
                                // temporarily using the client logo from the assest folder rather then from db
                                src={logo} 
                                alt="Company Logo"
                                width={400}
                                height={300}
                            />
                        )}

                        <div className="space-y-1 px-4 py-1.5 w-full">
                            <p className="text-2xl font-semibold mb-4">{orgData?.orgName}</p>
                            {orgData?.lAddress1 !== "" && <p className="text-sm">{orgData?.lAddress1}</p>}
                            {orgData?.lAddress2 !== "" && <p className="text-sm">{orgData?.lAddress2}</p>}
                            {orgData?.lAddress3 !== "" && <p className="text-sm">{orgData?.lAddress3}</p>}
                            <p className="text-sm">{orgData?.localSuburb} {orgData?.localState} {orgData?.localPostCode} {orgData?.localCountry}</p>
                            {orgData?.lPhoneNumber !== "" && <p className="text-sm"><span className="font-medium">Phone</span> - {orgData?.lPhoneNumber}</p>}
                            {orgData?.orgFax !== "" && <p className="text-sm"><span className="font-medium">Fax</span> - {orgData?.orgFax}</p>}
                            {orgData?.orgEmail !== "" && <p className="text-sm"><span className="font-medium">Email</span> - {orgData?.orgEmail}</p>}
                            {orgData?.orgWebAddress !== "" && <p className="text-sm"><span className="font-medium">Website</span> - {orgData?.orgWebAddress}</p>}
                        </div>

                    </div>
                </div>
            )}
        </div>

        <div className="flex justify-end mb-4 gap-4">
            <div className="flex items-center gap-2">
                <div className="flex gap-2">
                    <input type="radio" name="searchType" id="startWith" onChange={() => setSearchType(1)} />
                    <label htmlFor="startWith" className="text-sm cursor-pointer">Start with</label>
                </div>

                <div className="flex gap-2">
                    <input type="radio" name="searchType" id="like" onChange={() => setSearchType(2)} defaultChecked={true} />
                    <label htmlFor="like" className="text-sm cursor-pointer">Like</label>
                </div>
            </div>
            <input type="text" placeholder="Search agent name" className="px-4 py-2 border border-gray-300 rounded-md text-sm" onChange={(e) => handleSearchChange(e.target.value)}/>

            <div>
                <select className="px-4 py-2 border border-gray-300 rounded-md text-sm" onChange={(e) => setCountryId(parseInt(e.target.value))}>
                    <option value="0" className="text-gray-400">Select Country</option>
                    {userCodes?.map((code) => (
                        <option key={code.userCodeId} value={code.userCodeId}>{code.codeName}</option>
                    ))}
                </select>
            </div>
        </div>

        <div className="relative max-h-[500px] overflow-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right">
                <thead className="text-xs text-white tracking-wider uppercase bg-gray-50">
                    <tr className="sticky top-0 bg-[#2662C5]">
                        {orgData?.showLegalName === null ? (
                            <>
                                <th className="px-6 py-3">Agent Name</th>
                                <th className="px-6 py-3">Legal Name</th>
                            </>
                        ) : orgData?.showLegalName === false ? (
                            <th className="px-6 py-3">Agent Name</th>
                        ) : (
                            <th className="px-6 py-3">Legal Name</th>
                        )}

                        <th className="px-6 py-3">Phone #</th>
                        <th className="px-6 py-3">Email Address</th>
                        <th className="px-6 py-3">Address</th>
                        <th className="px-6 py-3">Country</th>
                        <th className="px-6 py-3">Principal Name</th>
                        {orgData?.displayTotalOffice && <th className="px-6 py-3">Total Offices</th>}
                    </tr>
                </thead>

                <tbody>

                    {agentLoading ? (
                        <tr>
                            <td><p className="px-6 py-3">Loading..</p></td>
                        </tr>
                    ) : (
                        agentListing?.data?.map((agent) => (
                            <tr key={agent.agentId} className="bg-white border-b border-b-gray-300 hover:bg-gray-50 even:bg-gray-50">
                                {orgData?.showLegalName === null ? (
                                    <>
                                        <td className="px-6 py-3">{agent.agentName}</td>
                                        <td className="px-6 py-3">{agent.legalName}</td>
                                    </>
                                ) : orgData?.showLegalName === false ? (
                                    <td className="px-6 py-3">{agent.agentName}</td>
                                ) : (
                                    <td className="px-6 py-3">{agent.legalName}</td>
                                )}
                                <td className="px-6 py-4">{agent.localPhoneNumber}</td>
                                <td className="px-6 py-4">{agent.email}</td>
                                <td className="px-6 py-4">{agent.localAddressLine1} {agent.localAddressLine2} {agent.localAddressLine3} {agent.name} {agent.state} {agent.postcode}</td>
                                <td className="px-6 py-4">{agent.country}</td>
                                <td className="px-6 py-4">{agent.principalName}</td>
                                {orgData?.displayTotalOffice && <td className="px-6 py-4">{agent.totalOffices}</td>}
                            </tr>
                        ))
                    )}
                    {/* {} */}
                </tbody>
            </table>
        </div>
        
        <div className="flex justify-between mt-4 text-sm">
            <div>
                <div className="flex gap-1">
                    <p>Showing <span className="font-medium">{(pageNo - 1) * pageLength + 1}</span></p>
                    <p>to <span className="font-medium">{Math.min(pageNo * pageLength, agentListing?.totalCount)}</span></p>
                    <p>of <span className="font-medium">{agentListing?.totalCount}</span> results</p>
                </div>
            </div>
            
            <div className="flex">
                
                <select className="px-3 h-8 font-medium bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700" onChange={(e) => setPageLength(parseInt(e.target.value))}>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="300">300</option>
                    <option value="400">400</option>
                    <option value="500">500</option>
                </select>

                <button onClick={pageNo > 1 ? () => setPagenNo(pageNo - 1) : () => {}} type="button" className={` ${pageNo > 1 ? "cursor-pointer" : "cursor-not-allowed"} flex items-center justify-center px-3 h-8 ms-3 font-medium text-white bg-[#2662C5] border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700`}>
                    Previous
                </button>

                <button onClick={pageNo < agentListing?.noOfPages ? () => setPagenNo(pageNo + 1) : () => {}} type="button" className={` ${pageNo < agentListing?.noOfPages ? "cursor-pointer" : "cursor-not-allowed"} flex items-center justify-center px-3 h-8 ms-3 font-medium text-white bg-[#2662C5] border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700`}>
                    Next
                </button>
            </div>
        </div>
    
    </main>
  );
};

export default AgentListing;
